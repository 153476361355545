/* General Styles */
body {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f7f9fc;
    color: #333;
    text-align: center;
}

.pricing-header {

    padding: 100px 20px; /* Added padding for smaller screens */
   
    color: white;
    text-align: center;
    background: linear-gradient(
        115deg,
        #61b6d2,
        #5d89c7,
        #3167d4,
        #23357e
      );
      background-size: 400% 400%;
      animation: gradientShift 10s ease-in-out infinite;
}

@keyframes gradientShift {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

/* Adjust font size to make it more responsive */
.pricing-header h1 {
    font-size: 2.5rem;
    margin-bottom: 10px;
    font-weight: bold;
    
    color: white;
}

.pricing-header p {
    font-size: 1.2rem;
    margin-bottom: 30px;
    font-weight: bold;
   
    color: white;
}

/* Pricing Section */
.pricing-container {
    display: flex;
    justify-content: center;
    align-items: stretch; /* Ensures cards have the same height */
    gap: 30px;
    padding: 50px;
    flex-wrap: wrap;
    background-color: #F5F5F7; /* Apple-like off-white/gray */
}

/* Make pricing cards equal in size */
.pricing-card {
    position: relative; /* Ensure the popular badge stays relative to the card */
    background-color: white;
    box-shadow: 0 0 50px rgba(0, 255, 38, 0.2);
    border-radius: 10px;
    padding: 30px;
    text-align: center;
    flex: 1 1 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 300px;
    transition: transform 0.3s ease;
    min-height: 470px;
    overflow: hidden;
}

.pricing-card:hover {
    transform: translateY(-10px);
}

.pricing-card h2 {
    font-size: 1.5rem;
    margin-bottom: 20px;
    color: #444464;
}

.price {
    font-size: 2rem;
    margin-bottom: 20px;
}
.pricing-card:hover {
    transform: translateY(-10px);
}

.popular {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #ff4500;
    color: white;
    font-weight: bold;
    padding: 5px 10px;
    border-radius: 5px;
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    z-index: 10; /* Ensure the badge is always on top */
}

.popular .fire-icon {
    margin-right: 5px;
}
.price span {
    font-size: 1rem;
    color: #666;
}

.features {
    list-style: none;
    padding: 0;
    margin-bottom: 20px;
}

.features li {
    font-size: 1rem;
    padding: 10px 0;
    border-bottom: 1px solid #eee;
}

.btn-pricing {
    background-color: #4a90e2;
    color: white;
    padding: 12px 25px;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.btn-pricing:hover {
    background-color: #005399;
}

/* Highlighting the Essential Plan */
.essential {
    background: white;
    box-shadow: 0 0 15px rgba(0, 0, 255, 0.2);
    transform: scale(1.05);
}


/* Trial Plan Styling */
.trial {
    background: white;
    box-shadow: 0 0 15px rgba(0, 255, 255, 0.2);
}

/* Modal Styles */
.modal-pricing {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}
  
.modal-content-pricing {
    background: white;
    padding: 30px;
    border-radius: 8px;
    text-align: center;
    max-width: 600px;
    width: 100%;
    transition: all 0.3s ease-in-out;
}
  
.modal-content-pricing h3 {
    margin-bottom: 20px;
    font-size: 1.5rem;
}

.os-options-grid {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
}

.os-option {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.os-option:hover {
    transform: scale(1.1);
}

.os-icon {
    font-size: 3rem;
    margin-bottom: 10px;
}

.os-option p {
    font-size: 1rem;
    margin: 0;
}

.close-btn {
    background-color: #ff4d4d;
    margin-top: 10px;
}
.close-btn-pricing {
    width: 70px;
    margin-top: 10px;
    border-radius: 10px;
    background-color: red;
    border: none;
}

.close-btn:hover {
    background-color: #ff1a1a;
}

.close-btn-pricing:hover {
    background-color: #ff1a1a;
}

/* Responsiveness for modal on mobile and tablet screens */
@media (max-width: 768px) {
    .modal-content-pricing {
        max-width: 90%;
        padding: 20px;
    }

    .modal-content-pricing h3 {
        font-size: 1.2rem;
    }

    .os-icon {
        font-size: 2.5rem;
    }

    .os-option p {
        font-size: 0.9rem;
    }
}

@media (max-width: 480px) {
    .modal-content-pricing {
        max-width: 90%;
        padding: 15px;
    }

    .modal-content-pricing h3 {
        font-size: 1rem;
    }

    .os-icon {
        font-size: 2rem;
    }

    .os-option p {
        font-size: 0.8rem;
    }

    .close-btn {
        font-size: 0.9rem;
        padding: 8px 15px;
    }
    .close-btn-pricing {
        font-size: 0.9rem;
        padding: 8px 15px;
    }
}

/* Responsive Design for Header */
@media (max-width: 768px) {
    .pricing-header {
        background-position: center;
        background-attachment: scroll; /* Disable fixed background on smaller screens */
        padding: 50px 20px; /* Reduce padding on smaller screens */
    }

    .pricing-header h1 {
        font-size: 2rem; /* Adjust the font size for smaller screens */
        margin-bottom: 15px;
    }

    .pricing-header p {
        font-size: 1rem; /* Adjust the paragraph font size for smaller screens */
    }
}

/* Responsive Design for Pricing Cards */
@media (max-width: 768px) {
    .pricing-container {
        flex-direction: column;
        padding: 20px;
    }

    .pricing-card {
        max-width: 100%;
        margin-bottom: 20px;
    }
}

@media (max-width: 480px) {
    .pricing-header h1 {
        font-size: 1.8rem; /* Further reduce the font size on mobile devices */
    }

    .pricing-header p {
        font-size: 0.9rem; /* Adjust paragraph size on smaller screens */
    }

    .pricing-header {
        padding: 30px 15px; /* Further reduce padding on mobile */
    }
}

/* Responsive Design for Header */
@media (max-width: 768px) {
    .pricing-header {
        background-position: center;
        background-attachment: scroll; /* Disable fixed background on smaller screens */
        padding: 50px 20px; /* Reduce padding on smaller screens */
    }

    .pricing-header h1 {
        font-size: 2rem; /* Adjust the font size for smaller screens */
        margin-bottom: 15px;
    }

    .pricing-header p {
        font-size: 1rem; /* Adjust the paragraph font size for smaller screens */
    }
}

/* Responsive Design for Pricing Cards */
@media (max-width: 768px) {
    .pricing-container {
        flex-direction: column;
        padding: 20px;
    }

    .pricing-card {
        max-width: 100%;
        margin-bottom: 20px;
    }
}

@media (max-width: 480px) {
    .pricing-header h1 {
        font-size: 1.8rem; /* Further reduce the font size on mobile devices */
    }

    .pricing-header p {
        font-size: 0.9rem; /* Adjust paragraph size on smaller screens */
    }

    .pricing-header {
        padding: 30px 15px; /* Further reduce padding on mobile */
    }
}

/* Checkout Form Styles */
.checkout-form {
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
  }
  
  .checkout-form h3 {
    margin-bottom: 20px;
  }
  
  .checkout-form .error {
    color: red;
    margin-bottom: 10px;
  }
  
  .checkout-form .success-message {
    text-align: center;
  }
  
  .checkout-form form {
    display: flex;
    flex-direction: column;
  }
  
  .checkout-form .btn {
    margin-top: 20px;
  }
  
  .checkout-form .close-btn {
    background-color: #ccc;
    margin-top: 10px;
  }
  

  .modal-success {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Dimmed background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.success-modal {
    background-color: white;
    padding: 30px;
    border-radius: 8px;
    text-align: center;
    max-width: 500px;
    width: 100%;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    animation: fadeIn 0.3s ease-in-out;
}

.success-modal h3 {
    font-size: 1.8rem;
    color: #4caf50; /* Success green */
    margin-bottom: 15px;
}

.success-modal p {
    font-size: 1rem;
    color: #333;
    margin-bottom: 20px;
}

.success-modal .btn {
    background-color: #4caf50; /* Success green */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.success-modal .btn:hover {
    background-color: #388e3c;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: scale(0.9);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

/* Error Modal Styles */
.modal.error-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Dimmed background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.error-modal .modal-content {
    background: white;
    padding: 30px;
    border-radius: 8px;
    text-align: center;
    max-width: 500px;
    width: 100%;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    animation: fadeIn 0.3s ease-in-out;
}

.error-modal h3 {
    font-size: 1.8rem;
    color: #f44336; /* Error red */
    margin-bottom: 15px;
}

.error-modal p {
    font-size: 1rem;
    color: #333;
    margin-bottom: 20px;
}

.error-modal .btn {
    background-color: #f44336; /* Error red */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.error-modal .btn:hover {
    background-color: #d32f2f; /* Darker red for hover */
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: scale(0.9);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}
