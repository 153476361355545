/* Container */
.container-signin {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  min-height: 100vh;
  font-family: 'Poppins', sans-serif;
  margin: 0;
  background-color: #f9f9f9;
}

/* Card */
.card-signin {
  display: flex;
  background: white;
  border-radius: 20px;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  max-width: 1200px;
  width: 100%;
  height: 70%;
  flex-direction: row;
}

/* Form Section */
.form-section-signin {
  padding: 40px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
}

/* Title */
.title {
  font-size: 2.5em;
  text-align: center;
  color: #333;
  margin-bottom: 10px;
}

.brand-name {
  color: #00C9FF;
}

/* Subtitle */
.subtitle {
  font-size: 1em;
  color: #666;
  text-align: center;
  margin-bottom: 30px;
}

/* Input */
.input-wrapper-signin {
  margin-bottom: 15px;
}

.signin[type="text"],
.signin[type="email"],
.signin[type="tel"],
.signin[type="password"] {
  width: 100%; /* Ensure full width in responsive mode */
  padding: 15px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 10px;
  font-size: 1em;
  background-color: #f9f9f9;
  box-sizing: border-box; /* Ensure padding does not overflow */
}

input:focus {
  outline: none;
  border-color: #00C9FF;
  box-shadow: 0 0 5px #00C9FF;
}

/* Button */
.button {
  background: #00C9FF;
  color: white;
  padding: 15px;
  font-size: 1em;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-transform: uppercase;
  width: 100%; /* Ensure full width of button */
}

.button:hover {
  background: #009dd6;
  box-shadow: 0 10px 20px rgba(0, 191, 255, 0.8);
}

/* Checkbox */
.checkbox-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.checkbox {
  margin-right: 10px;
  transform: scale(1.2);
}

.checkbox-label {
  font-size: 0.9em;
}

/* Link */
.link-wrapper {
  text-align: center;
}

.small-text a {
  color: #00C9FF;
  text-decoration: none;
}

.small-text a:hover {
  text-decoration: underline;
}

/* Image Section */
.image-section {
  flex: 1;
  background: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 85%);
}

.image {
  max-width: 80%;
  height: auto;
  border-radius: 15px;
}

/* Responsive - Tablets and smaller */
@media (max-width: 1024px) {
  .card-signin {
    height: auto;
  }

  .form-section-signin {
    padding: 30px;
  }

  .title {
    font-size: 2em;
  }
}

/* Responsive - Mobile Phones */
@media (max-width: 768px) {
  .card-signin {
    flex-direction: column;
    height: auto;
  }

  .form-section-signin {
    padding: 20px;
  }

  .title {
    font-size: 1.8em;
  }

  .image-section {
    display: none; /* Hides the image section on mobile */
  }

  /* Ensure inputs and button fit full width on mobile */
  .signin[type="text"],
  .signin[type="email"],
  .signin[type="tel"],
  .signin[type="password"],
  .button {
    width: 100%; /* Full width on mobile */
  }
}

/* Responsive - Smaller Mobile Phones */
@media (max-width: 480px) {
  .form-section-signin {
    padding: 15px;
  }

  .title {
    font-size: 1.5em;
  }

  .button {
    padding: 12px;
  }

  .image-section {
    padding: 15px;
  }

  .image {
    max-width: 100%;
  }
}
/* Modal container */
.modal-1 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); /* Dark overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal content box */
.modal-content-1 {
  position: relative; /* To position close button relative to this content */
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  width: 500px; /* Desktop size */
  text-align: center;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
}

.modal-content-1 h2 {
  margin-bottom: 15px;
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

.modal-content-1 p {
  margin-bottom: 20px;
  font-size: 14px;
  color: #666;
}

/* Input inside the modal */
.signin-holder {
  width: 100%;
  padding: 12px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid #ddd;
  font-size: 1em;
}

.modal-button {
  width: 100%;
  padding: 12px;
  background-color: #00C9FF;
  color: white;
  border: none;
  border-radius: 10px;
  font-size: 1rem;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

/* Close button */
.close-button-signin {
  position: absolute; /* Absolute positioning inside modal */
  top: 10px;
  right: 15px;
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  cursor: pointer;
  transition: color 0.3s ease;
}

/* Close button hover effect */
.close-button-signin:hover {
  color: #ff4d4d;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .modal-content-1 {
    width: 90%; /* Adjust modal size for tablets and smaller screens */
  }

  .close-button-signin {
    font-size: 1.2rem;
    top: 8px;
    right: 12px;
  }
}

@media (max-width: 480px) {
  .modal-content-1 {
    width: 100%; /* Full width on small mobile */
    padding: 20px;
  }

  .close-button-signin {
    font-size: 1rem;
    top: 6px;
    right: 10px;
  }
}

.signin-holder:focus {
  background-color: #ffffff;
}

.modal-button:hover {
  background-color: #ffffff;
}

.back-button-signin{
  font-size: 16px;
  color: #000000; /* Blue color for the back button */
 
  background-color: white;
  display: flex;
  justify-content: flex-start;
  border: none;
}