/* Main section styling */
.feature-family {
    text-align: center;
    padding: 50px 0;
    font-family: 'Arial', 'Helvetica', sans-serif;
    background-color: #F5F5F7; /* Apple-like off-white/gray */
}

.feature-family h2 {
    font-size: 2rem;
    margin-bottom: 30px;
    color: #1D1D1F;
    
}

/* Grid layout for feature items */
.feature-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 110px; /* Add space between grid items */
    max-width: 1200px;
    margin: 0 auto;
}

/* Left side large feature item */
.feature-large {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.feature-large img {
    width: 100%;
    height: auto;
    
    border-radius: 10px;
}

.feature-large h3 {
    margin-top: 15px;
    margin-bottom: 10px;
    font-size: 1.5rem;
    
    color: #1D1D1F;
}

.feature-large p {
    font-size: 1rem;
    margin-bottom: 15px;
    color: #555;
}

/* Right side smaller feature items stacked */
.right-side {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px; /* Space between stacked items */
}

.feature-item {
    padding: 20px;
    margin-bottom: 20px; /* Add space between individual feature items */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.feature-item img {
    width: 100%;
    height: auto;
    border-radius: 10px;
}

.feature-item h3 {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 1.5rem;
    font-weight: 600;
    color: #333;
}

.feature-item p {
    font-size: 1rem;
    color: #555;
    margin-bottom: 15px;
}
.feature-item a {
    text-decoration: none !important;
    color: inherit;
}

/* Hover effect */
.feature-item:hover {
    transform: scale(1.05);
}

/* Responsive Design for Tablets and Mobile */
@media (max-width: 768px) {
    .feature-grid {
        grid-template-columns: 1fr;
        gap: 30px;
    }
}
