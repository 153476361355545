/* Use an Apple-inspired system font stack for a clean, premium look */
.products-page {
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #1D1D1F; /* Apple's near-black text color */
  overflow-x: hidden;
  background-color: #F5F5F7; /* Apple-like off-white background */
  margin: 0;
  padding: 0;
}
.section-wrapper {
  background: var(--background-light); /* Use CSS variables as defined earlier */
  background-size: cover;
  transition: background 1s ease;
  min-height: 100vh; /* Ensure the section occupies at least the full viewport height */
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden; /* Prevent internal scrolling */
  color: #fff;
}

/* Hero Section
   - Large, bold text
   - Subtle gradient or image in the background
*/
.hero-section {
  min-height: 100vh; 
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  text-align: center;
  color: #1D1D1F;
  padding: 60px 20px;
  
  /* Updated gradient and animation */
  background: linear-gradient(
    115deg,
    #61b6d2,
    #5d89c7,
    #3167d4,
    #23357e
  );
  background-size: 400% 400%;
  animation: gradientShift 10s ease-in-out infinite;
}

/* Define the keyframes if not already defined in App.css */
@keyframes gradientShift {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
/* Center container for hero content */
.hero-content {
  max-width: 700px;
  margin: 0 auto;
}

/* Large, gradient-based heading for a modern "tech" pop */
.hero-title {
  font-size: 2.8rem;
    font-weight: 700;
    line-height: 1.2;
    background: linear-gradient(to right, #9bc1ff, #72f0e6);
    -webkit-background-clip: text;
    color: transparent;
}

/* Subheading with a smaller gradient text effect if desired */
.gradient-text2 {
  font-weight: 600;
  background: linear-gradient(to right, #284262, #4444d2);
  -webkit-background-clip: text;
  color: transparent;
}

.hero-subtitle {
  font-size: 1.4rem;
  margin-bottom: 30px;
  font-weight: 400;
  color: #ffffff;
}

/* CTA buttons container */
.cta-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 30px;
}

/* Primary button styling */
.cta-button {
  padding: 14px 28px;
  border: none;
  border-radius: 24px;
  font-size: 1.1rem;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.3s ease, transform 0.2s ease;
  outline: none;
  color: #fff;
}

/* "Learn More" button */
.learn-more {
  background-color: #0071E3; /* Apple-like blue */
}
.learn-more:hover {
  background-color: #005BB5;
  transform: translateY(-2px);
}

/* "Buy Now" or "Try For Free" button */
.buy-now {
  background-color: #34C759; /* Apple-like green */
}
.buy-now:hover {
  background-color: #28A745;
  transform: translateY(-2px);
}

/* =================== Key Feature Section =================== */
.key-feature-section {
  background-color: #F5F5F7; /* Apple-like off-white/gray */
  padding: 60px 20px;
  text-align: center;
}

/* A heading for the Key Feature section, if desired */
.key-feature-section h2 {
  font-size: 2.2rem;
  font-weight: 600;
  color: #1D1D1F;
  margin-bottom: 40px;
}

/* Flexbox or grid layout for feature items */
.key-feature-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 24px;
}

.key-feature-item {
  background-color: #F5F5F7;
  border-radius: 12px;
  width: 260px;
  min-height: 200px;
  padding: 20px;
  text-align: center;
  box-shadow: 0 2px 6px rgba(0,0,0,0.13);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin: 0 auto;
}

.key-feature-item:hover {
  transform: translateY(-4px);
  box-shadow: 0 4px 12px rgba(0,0,0,0.08);
}

.key-feature-item h1 {
  font-size: 2.2rem;
  color: #0071E3; /* Subtle brand color or Apple blue */
  margin-bottom: 10px;
}

.key-feature-item h3 {
  font-size: 1.1rem;
  font-weight: 500;
  margin-bottom: 8px;
  color: #333;
}

.key-feature-item p {
  font-size: 0.95rem;
  color: #555;
  line-height: 1.5;
}

/* =================== 3D Section (imported) =================== */
/* If your <Section/> component has its own internal styling, you can override or refine it here */


/* ================== Parallax / Platform Section ================== */
/* Optional "parallax" effect: Apple typically does not do heavy parallax,
   but you can keep a subtle background image or gradient. */
.parallax-section {
  min-height: 400px;
  background-color: #F5F5F7; /* Apple-like off-white/gray */
  background-size: cover;
  background-attachment: fixed; /* Parallax effect if desired */
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #1D1D1F;
  padding: 60px 20px;
}

.parallax-content {
  max-width: 600px;
  margin: 0 auto;
}

.parallax-content h2 {
  font-size: 2rem;
  
  margin-bottom: 20px;
  color: #1D1D1F;
}

.parallax-content p {
  font-size: 1rem;
  color: #333;
  margin-bottom: 30px;
}

/* Platform download buttons container */
.platform-buttons1 {
  display: flex;
  justify-content: center;
  gap: 40px;
  flex-wrap: wrap;
}

.platform-button-container1 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.platform-text1 {
  margin-top: 10px;
  font-size: 0.9rem;
  color: #333;
}

/* Individual platform buttons with subtle glow */
.platform-button1 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  border: none;
  border-radius: 14px;
  background-color: #FFFFFF;
  color: #000;
  font-size: 1.2rem;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  box-shadow: 0 2px 6px rgba(0,0,0,0.1);
  cursor: pointer;
}

.platform-button1:hover {
  box-shadow: 0 4px 12px rgba(0,0,0,0.15);
  transform: translateY(-3px);
}

/* Subtle color-coded glows */


/* =================== Responsive Breakpoints =================== */

/* Larger Screens (Min-Width 1200px or so) */
@media (min-width: 1200px) {
  .hero-title {
    font-size: 4rem;
  }
}

/* Tablets */
@media (max-width: 1024px) {
  .key-feature-grid {
    flex-wrap: wrap;
    gap: 24px;
  }
  .key-feature-item {
    width: 45%;
    margin-bottom: 20px;
  }
}

/* Mobile Landscape and Below */
@media (max-width: 768px) {
  .hero-section{
    min-height: 60vh;
  }
  .hero-title {
    font-size: 2.8rem;
  }
  .cta-button {
    font-size: 1rem;
    padding: 12px 20px;
  }
  .key-feature-item {
    width: 100%;
    max-width: 350px;
  }
  .platform-button1 {
    width: 60px;
    height: 60px;
  }
}

/* Mobile Portrait (max-width: 480px) */
@media (max-width: 480px) {
  .hero-title {
    font-size: 2.2rem;
  }
  .hero-subtitle {
    font-size: 1rem;
  }
  .platform-button1 {
    width: 50px;
    height: 50px;
  }
  .platform-text1 {
    font-size: 0.85rem;
  }
}
