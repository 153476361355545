/* -------------------- Global Styles -------------------- */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, Arial, sans-serif;
}

/* -------------------- Download Page -------------------- */
.download-page {
  /* Soft, tech-inspired gradient background */
  
  color: #1D1D1F; /* Apple near-black text color for headings */
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem; /* Add padding for smaller screens */
  text-align: center;
 
  background-color: #F5F5F7; /* Apple-like off-white/gray */
}

@keyframes gradientShift {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.main-content-download {
  background: linear-gradient(
    115deg,
    #61b6d2,
    #5d89c7,
    #3167d4,
    #23357e
  );
  background-size: 400% 400%;
  animation: gradientShift 10s ease-in-out infinite;
  border-radius: 16px;
  padding: 4rem 2rem;
  width: 100%;
  max-width: 800px; 
  margin: 2rem auto;
  box-shadow: 0 2px 12px rgba(0,0,0,0.1);
}

.title-download {
  font-size: 2.5rem;
  font-weight: 700;
  color: #fff; /* Contrast with gradient background */
  margin-bottom: 1rem;
}

.subtitle-download {
  font-size: 1.25rem;
  color: rgba(255, 255, 255, 0.85);
  margin-bottom: 2rem;
  line-height: 1.6;
  max-width: 600px;
  margin: 0 auto 2rem auto; /* center text block */
}

/* -------------------- Platform Buttons -------------------- */
.platform-buttons-download {
  display: flex;
  justify-content: center;
  gap: 32px;
  flex-wrap: wrap;
  margin-top: 30px;
}

.platform-button-container-download {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative; /* needed for dropdown positioning */
}

.platform-text-download {
  margin-top: 10px;
  color: #fff;
  font-size: 1rem;
}

/* -------------------- Platform Button Styling -------------------- */
.platform-button-download {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border: none;
  border-radius: 14px;
  background-color: #FFFFFF;
  color: #000;
  font-size: 1.1rem;
  font-weight: 500;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  cursor: pointer;
  box-shadow: 0 2px 6px rgba(0,0,0,0.15);
}

.platform-button-download:hover {
  box-shadow: 0 6px 15px rgba(0,0,0,0.2);
  transform: translateY(-3px);
}

/* MacOS-specific button (optional subtle tint) */
.macos-button-download {
  /* Example: box-shadow: 0 2px 8px rgba(0, 150, 255, 0.2); */
}

/* Windows-specific button (optional subtle tint) */
.windows-button-download {
  /* Example: box-shadow: 0 2px 8px rgba(0, 255, 0, 0.2); */
}

/* -------------------- Mac Dropdown Styles -------------------- */
.mac-dropdown-container {
  /* position: relative is already set on the parent container */
}

.mac-dropdown-menu {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 90px; /* position below the Mac button */
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.2);
  overflow: hidden;
  min-width: 140px;
  z-index: 10;
}

.mac-dropdown-item {
  background: transparent;
  border: none;
  padding: 0.75rem 1rem;
  text-align: left;
  font-size: 0.95rem;
  cursor: pointer;
  transition: background 0.2s ease;
  outline: none; /* Remove focus outlines if desired */
}

.mac-dropdown-item:hover,
.mac-dropdown-item:focus {
  background: rgba(0, 0, 0, 0.1);
}

/* -------------------- Responsive Design -------------------- */
@media (max-width: 1024px) {
  .title-download {
    font-size: 2.2rem;
  }
  .subtitle-download {
    font-size: 1.1rem;
  }
  .platform-button-download {
    width: 70px;
    height: 70px;
  }
  .platform-text-download {
    font-size: 0.9rem;
  }
}

@media (max-width: 768px) {
  .title-download {
    font-size: 2rem;
  }
  .subtitle-download {
    font-size: 1rem;
  }
  .platform-buttons-download {
    gap: 16px;
  }
  .platform-button-download {
    width: 65px;
    height: 65px;
  }
}

@media (max-width: 480px) {
  .title-download {
    font-size: 1.8rem;
  }
  .subtitle-download {
    font-size: 0.9rem;
  }
  .platform-buttons-download {
    flex-direction: column;
    gap: 20px;
  }
  .platform-button-download {
    width: 60px;
    height: 60px;
  }
  .platform-text-download {
    font-size: 0.8rem;
  }
}
