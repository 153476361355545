/* Overlay to cover the entire screen */
.cookie-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(28, 28, 30, 0.5); /* Semi-transparent dark background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it sits above other elements */
  }
  
  /* Popup Card */
  .cookie-popup-card {
    background-color: #FFFFFF; /* Clean white background */
    border-radius: 16px; /* Rounded corners */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    width: 90%;
    max-width: 400px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    animation: fadeIn 0.3s ease-out;
  }
  
  /* Icon Styling */
  .cookie-popup-icon {
    margin-bottom: 16px;
  }
  
  /* Content Styling */
  .cookie-popup-content h3 {
    font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #1D1D1F;
    font-size: 1.5rem;
    margin-bottom: 12px;
  }
  
  .cookie-popup-content p {
    font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #3A3A3C;
    font-size: 1rem;
    text-align: center;
    margin-bottom: 24px;
  }
  
  /* Buttons Container */
  .cookie-popup-buttons {
    display: flex;
    gap: 12px;
  }
  
  /* Button Styling */
  .cookie-button {
    flex: 1;
    padding: 10px 0;
    border: none;
    border-radius: 8px;
    font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 0.95rem;
    cursor: pointer;
    transition: background-color 0.2s ease, transform 0.2s ease;
  }
  
  .cookie-button.accept {
    background-color: #0071E3; /* Apple-like blue */
    color: #FFFFFF;
  }
  
  .cookie-button.accept:hover {
    background-color: #005BB5;
    transform: translateY(-2px);
  }
  
  .cookie-button.decline {
    background-color: #E5E5EA; /* Light gray */
    color: #1D1D1F;
  }
  
  .cookie-button.decline:hover {
    background-color: #D4D4D8;
    transform: translateY(-2px);
  }
  
  /* Fade-in Animation */
  @keyframes fadeIn {
    from { opacity: 0; transform: scale(0.95); }
    to { opacity: 1; transform: scale(1); }
  }
  
  /* Responsive Design */
  @media (max-width: 480px) {
    .cookie-popup-card {
      padding: 16px;
    }
  
    .cookie-popup-content h3 {
      font-size: 1.3rem;
    }
  
    .cookie-popup-content p {
      font-size: 0.9rem;
    }
  
    .cookie-button {
      font-size: 0.85rem;
    }
  }
  