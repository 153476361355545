/* Main section styling */
.feature-section {
  padding: 50px 20px;
  text-align: center;
  background-color: #F5F5F7; /* Apple-like off-white/gray */
}

.feature-content h2 {
  font-size: 1.8rem;
  font-weight: normal;
  color: #1D1D1F;
  line-height: 1.5;
}

.feature-content .highlight {
  color: #0078d4; /* Blue color */
  font-weight: bold;
}

/* Styling for the feature links (icons and text) */
.feature-links {
  display: flex;
  justify-content: center;
  gap: 50px;
  margin-top: 30px;
}

.feature-item1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1rem;
}

.feature-item1 .icon {
  color: #0078d4; /* Make icons blue */
  margin-bottom: 10px;
}

.feature-item1 span {
  color: #0078d4; /* Maintain the blue color for the text */
  font-weight: bold;
  text-decoration: none; /* No underline since it's not a link */
}

.feature-item1 span:hover {
  text-decoration: none; /* No hover underline */
}


/* Responsive Design for Mobile Devices */
@media (max-width: 768px) {
  .feature-links {
    flex-direction: column; /* Stack items vertically */
    gap: 30px; /* Adjust gap for vertical stacking */
  }

  .feature-content h2 {
    font-size: 1.5rem; /* Reduce font size on mobile */
  }

  .feature-item1 {
    font-size: 0.9rem; /* Adjust font size */
  }
}

/* Further adjustments for very small mobile devices */
@media (max-width: 480px) {
  .feature-content h2 {
    font-size: 1.2rem; /* Reduce font size more for smaller screens */
  }

  .feature-links {
    gap: 20px; /* Narrower gap for very small devices */
  }

  .feature-item1 {
    font-size: 0.8rem; /* Smaller text for small devices */
  }

  .feature-item1 .icon {
    size: 30px; /* Reduce icon size */
  }
}
