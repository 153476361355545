/* Use an Apple-inspired system font stack */
body {
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #101010; /* near-black background for a "dark mode" Apple vibe */
  color: #f2f2f2; /* Light text against dark background */
}

/* Main content container */
.main-content {
  position: relative;
  padding: 80px 20px;
  min-height: 100vh;
  box-sizing: border-box;
}

/* 
  Subtle animated gradient background 
  for a futuristic/tech vibe.
*/
.tech-bg {
  background: linear-gradient(
    115deg,
    #61b6d2,
    #5d89c7,
    #3167d4,
    #23357e
  );
  background-size: 400% 400%;
  animation: gradientShift 10s ease-in-out infinite;
}

/* Keyframes for the background gradient animation */
@keyframes gradientShift {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* Container for the main logo at the top */
.imagecontainer {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

.Logomain {
  width: 320px;
  height: auto;
  object-fit: contain;
}

/* Grid container for text + side image */
.content-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 40px;
  max-width: 1200px;
  margin: 0 auto;
}

/* Left content styles */
.left-content {
  text-align: left;
}

/* Large, Apple-like heading with a subtle gradient & glow */
.gradient-text {
  font-size: 2.8rem;
  font-weight: 700;
  line-height: 1.2;
  background: linear-gradient(to right, #9bc1ff, #72f0e6);
  -webkit-background-clip: text;
  color: transparent;
  margin-bottom: 20px;
  position: relative;
}

/* Soft glow on the text for a tech edge */
.glow-text {
  text-shadow: 0 0 10px rgba(80, 255, 200, 0.4);
}

/* Body paragraph with more Apple-like spacing and line-height */
.tech-paragraph {
  font-size: 1.1rem;
  line-height: 1.7;
  color: #dcdcdc;
  max-width: 700px;
  margin-bottom: 20px;
  font-weight: 300;
}

/* Buttons container */
.buttons {
  margin-top: 30px;
  display: flex;
  gap: 20px;
}

/* Buttons with minimal round corners, subtle transitions */
.blue-button1,
.blue-button {
  padding: 12px 28px;
  border: none;
  border-radius: 24px;
  font-size: 1rem;
  cursor: pointer;
  background-color: #0071e3; /* Apple-esque blue */
  color: #ffffff;
  transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.2s ease;
  font-weight: 500;
  outline: none;
}

.blue-button {
  background-color: #34c759; /* Another Apple-like green for the second button */
}

/* Subtle neon hover effect */
.blue-button1:hover,
.blue-button:hover {
  box-shadow: 0 0 16px rgba(144, 224, 239, 0.4);
  transform: translateY(-2px);
}

/* Additional neon-button class (if desired) for pulsating effect */
.neon-button {
  position: relative;
  overflow: hidden;
}
.neon-button:before {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 80%);
  animation: neon-pulse 2.5s infinite;
  opacity: 0;
}
@keyframes neon-pulse {
  0% {
    transform: translate(-50%, -50%) scale(0.7);
    opacity: 0.5;
  }
  50% {
    transform: translate(-50%, -50%) scale(1.4);
    opacity: 0;
  }
  100% {
    transform: translate(-50%, -50%) scale(2);
    opacity: 0;
  }
}

/* Right content (image) with a subtle 3D float animation */
.right-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Slight 3D rock animation for the side image */
.side-image {
  width: 100%;
  max-width: 600px;
  transform-style: preserve-3d;
  transform-origin: center center;
  animation: float3D 6s ease-in-out infinite;
  opacity: 1;
}

@keyframes float3D {
  0% {
    transform: perspective(1000px) rotateY(-4deg) translateY(5px) scale(0.98);
  }
  50% {
    transform: perspective(1000px) rotateY(4deg) translateY(-5px) scale(1.02);
  }
  100% {
    transform: perspective(1000px) rotateY(-4deg) translateY(5px) scale(0.98);
  }
}

/* Enlarge on hover for a more dynamic feel */
.side-image:hover {
  transform: scale(1.04) perspective(1000px) rotateY(-3deg) translateY(2px);
  transition: transform 0.3s ease;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .content-container {
    grid-template-columns: 1fr;
    text-align: center;
  }
  .left-content {
    text-align: center;
  }
}

@media (max-width: 768px) {
  .gradient-text {
    font-size: 2.2rem;
  }
  .tech-paragraph {
    font-size: 1rem;
    max-width: 100%;
    margin: 0 auto 20px auto;
  }
  .Logomain {
    width: 260px;
  }
}

@media (max-width: 480px) {
  .gradient-text {
    font-size: 1.8rem;
  }
  .tech-paragraph {
    font-size: 0.95rem;
  }
  .Logomain {
    width: 200px;
  }
}
