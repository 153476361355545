.logo-section {
    text-align: center;
    padding: 50px 20px;
    background-color: #F5F5F7; /* Apple-like off-white/gray */
  }
  
  .logo-section-title {
    font-size: 24px;
   
    margin-bottom: 20px;
    color: #1D1D1F;
  }
  
  .logo-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 30px; /* Spacing between logos */
    
  }
  
  .logo {
    max-height: 80px;
    object-fit: contain;
    margin: 0 10px;
  }
  