/* PrivacyPolicy.css */

/* Root Variables for Consistency */
:root {
  --font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  --primary-color: #0071e3; /* Apple-esque blue */
  --secondary-color: #333; /* Dark gray for text */
  --background-color: #F5F5F7; /* Apple-like off-white/gray */
  --heading-color: #0056b3; /* Darker blue for headings */
  --link-color: #0071e3; /* Consistent link color */
  --link-hover-color: #0056b3; /* Darker blue on hover */
  --box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  --border-radius: 8px;
  --padding: 40px 20px;
}

/* Container Styles */
.privacy-policy-container {
  max-width: 960px;
  margin: 60px auto; /* Increased top and bottom margin for better spacing */
  padding: var(--padding);
  font-family: var(--font-family);
  background-color: var(--background-color);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  line-height: 1.8;
  color: var(--secondary-color);
}

/* Heading Styles */
.privacy-policy-container h1 {
  font-size: 2.8rem;
  color: var(--secondary-color);
  text-align: center;
  margin-bottom: 40px;
  font-weight: 700;
}

.privacy-policy-container h2 {
  font-size: 1.8rem;
  color: var(--heading-color);
  margin-bottom: 20px;
  font-weight: 600;
}

/* Paragraph Styles */
.privacy-policy-container p {
  font-size: 1.1rem;
  color: #555;
  margin-bottom: 25px;
}

/* Final Note Styling */
.final-note {
  font-size: 1.1rem;
  color: var(--secondary-color);
  text-align: center;
  font-weight: 500;
  margin-top: 40px;
  padding-top: 20px;
  border-top: 1px solid #ddd;
}

/* Link Styling */
.privacy-policy-container a {
  color: var(--link-color);
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease;
}

.privacy-policy-container a:hover {
  color: var(--link-hover-color);
  text-decoration: underline;
}

/* List Styling */
.privacy-policy-container ul {
  list-style: disc inside;
  padding-left: 20px;
}

.privacy-policy-container ul li {
  margin-bottom: 10px;
  font-size: 1rem;
  color: #555;
}

/* Enhanced Section Styling */
.privacy-policy-container section {
  margin-bottom: 40px;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .privacy-policy-container {
    max-width: 80%;
    padding: 30px 15px;
  }

  .privacy-policy-container h1 {
    font-size: 2.4rem;
    margin-bottom: 30px;
  }

  .privacy-policy-container h2 {
    font-size: 1.6rem;
    margin-bottom: 18px;
  }

  .privacy-policy-container p {
    font-size: 1rem;
    margin-bottom: 20px;
  }

  .final-note {
    font-size: 1rem;
    margin-top: 30px;
  }
}

@media (max-width: 768px) {
  .privacy-policy-container {
    max-width: 90%;
    padding: 25px 15px;
  }

  .privacy-policy-container h1 {
    font-size: 2rem;
    margin-bottom: 25px;
  }

  .privacy-policy-container h2 {
    font-size: 1.4rem;
    margin-bottom: 16px;
  }

  .privacy-policy-container p {
    font-size: 0.95rem;
    margin-bottom: 18px;
  }

  .final-note {
    font-size: 0.95rem;
    margin-top: 25px;
  }
}

@media (max-width: 480px) {
  .privacy-policy-container {
    max-width: 95%;
    padding: 20px 10px;
  }

  .privacy-policy-container h1 {
    font-size: 1.8rem;
    margin-bottom: 20px;
  }

  .privacy-policy-container h2 {
    font-size: 1.2rem;
    margin-bottom: 14px;
  }

  .privacy-policy-container p {
    font-size: 0.9rem;
    margin-bottom: 16px;
  }

  .final-note {
    font-size: 0.9rem;
    margin-top: 20px;
  }
}
