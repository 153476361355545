.about-us-page {
    width: 100%;
    height: 100vh; /* Full viewport height */
  }
  
  .about-hero-section {
    background-image: url('./aboutus.webp'); /* Make sure the image path is correct */
    background-size: cover;
    background-position: center;
    height: 100vh; /* Full viewport height */
    position: relative;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Black overlay with opacity */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start; /* Align text to the left */
    padding-left: 50px; /* Adjust padding for positioning */
    color: white;
  }
  
  .overlay h1 {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .overlay p {
    font-size: 1.5rem;
    max-width: 600px; /* Limit the width of the paragraph */
  }
  