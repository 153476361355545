/* -------------------- General Layout & Body -------------------- */
body {
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, Arial, sans-serif;
  background-color: #F5F5F7; /* Apple-like off-white/gray */
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Container that centers page content */
.contact-us-page {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 50px;
  background-color: #F5F5F7; /* Apple-like off-white/gray */
}

/* Outer container holding left (info) + right (form) sections */
.contact-container {
  display: flex;
  max-width: 1200px;
  width: 100%;
  background-color: #FFFFFF;
  border-radius: 12px;
  box-shadow: 0 2px 12px rgba(0,0,0,0.06);
  overflow: hidden;
}

/* -------------------- Left Section: Contact Info -------------------- */
.contact-info {
  flex: 1;
  padding: 40px;
  
  color: #FFFFFF;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: linear-gradient(
    115deg,
    #61b6d2,
    #5d89c7,
    #3167d4,
    #23357e
  );
  background-size: 400% 400%;
  animation: gradientShift 10s ease-in-out infinite;
}
@keyframes gradientShift {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* Title in left section */
.contact-title {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 20px;
  color: #FFFFFF;
}

/* Paragraph text / descriptions */
.contact-description {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 15px;
  color: #FFFFFF;
}

/* Accent color for links inside description text */
.contact-description a {
  color: #ffffff;
  text-decoration: none;
}
.contact-description a:hover {
  text-decoration: underline;
}

/* -------------------- Right Section: Contact Form -------------------- */
.contact-form-container {
  flex: 1;
  padding: 40px;
  background-color: #F5F5F7;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* The form itself */
.contact-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Label + Input/Textarea/Select */
.form-group {
  display: flex;
  flex-direction: column;
}

/* Labels */
.form-group label {
  font-size: 1rem;
  color: #1D1D1F;
  margin-bottom: 6px;
  font-weight: 500;
}

/* Inputs, Textareas, Selects */
.form-group input,
.form-group textarea,
.form-group select {
  width: 100%;
  padding: 12px;
  border: 1px solid #CCC;
  border-radius: 8px;
  font-size: 0.95rem;
  color: #333;
  background-color: #FFF;
  outline: none;
  box-sizing: border-box;
  font-family: inherit;
}

.form-group textarea {
  resize: vertical;
}

/* Submit button */
.submit-button-1 {
  padding: 14px;
  background-color: #0071E3; /* Apple-like blue */
  color: #FFFFFF;
  border: none;
  border-radius: 24px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  font-weight: 500;
}
.submit-button-1:hover {
  background-color: #005BB5;
  transform: translateY(-2px);
}

/* -------------------- Thank You Message -------------------- */
.thank-you-message {
  text-align: center;
  padding: 40px;
  color: #1D1D1F;
}
.thank-you-message h2 {
  font-size: 2rem;
  margin-top: 20px;
  font-weight: 600;
}
.thank-you-message p {
  font-size: 1.1rem;
  margin-top: 10px;
  line-height: 1.6;
}
.thank-you-message svg {
  font-size: 50px;
}

/* -------------------- Responsive Styles -------------------- */
/* Tablets (<= 1024px) */
@media (max-width: 1024px) {
  .contact-container {
    flex-direction: column;
    box-shadow: none;
  }
  .contact-info,
  .contact-form-container {
    padding: 30px;
  }
  .contact-title {
    font-size: 1.8rem;
  }
  .form-group input,
  .form-group textarea,
  .form-group select {
    font-size: 0.9rem;
  }
  .submit-button-1 {
    font-size: 0.95rem;
  }
  .thank-you-message h2 {
    font-size: 1.8rem;
  }
  .thank-you-message svg {
    font-size: 40px;
  }
}

/* Mobile (<= 768px) */
@media (max-width: 768px) {
  .contact-us-page {
    padding: 20px;
  }
  .contact-info,
  .contact-form-container {
    padding: 20px;
  }
  .contact-title {
    font-size: 1.6rem;
    text-align: center;
  }
  .contact-description {
    font-size: 0.9rem;
    margin-bottom: 10px;
    text-align: center;
  }
  .form-group input,
  .form-group textarea,
  .form-group select {
    font-size: 0.9rem;
    padding: 10px;
  }
  .submit-button-1 {
    padding: 12px;
    font-size: 1rem;
  }
  .thank-you-message {
    padding: 20px;
  }
  .thank-you-message h2 {
    font-size: 1.5rem;
  }
  .thank-you-message svg {
    font-size: 35px;
  }
}

/* Very small screens (<= 480px) */
@media (max-width: 480px) {
  .contact-us-page {
    padding: 10px;
  }
  .contact-title {
    font-size: 1.4rem;
  }
  .contact-description {
    font-size: 0.85rem;
  }
  .form-group input,
  .form-group textarea,
  .form-group select {
    font-size: 0.85rem;
    padding: 8px;
  }
  .submit-button-1 {
    font-size: 0.9rem;
    padding: 10px;
    border-radius: 20px;
  }
  .thank-you-message h2 {
    font-size: 1.3rem;
  }
  .thank-you-message p {
    font-size: 0.9rem;
  }
  .thank-you-message svg {
    font-size: 30px;
  }
}
