/* Container */
.container2 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f4f7f9;
  padding: 0 20px;
  box-sizing: border-box;
}

/* Card */
.card2 {
  background-color: white;
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
  text-align: center;
  box-sizing: border-box;
}

/* Title */
.card2 h1 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

/* Subtitle */
.card2 p {
  font-size: 16px;
  color: #666;
  margin-bottom: 20px;
}

/* Input Field */
.card2 input[type="text"] {
  width: 100%;
  padding: 12px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 16px;
  box-sizing: border-box;
  transition: border-color 0.3s ease;
}

.card2 input[type="text"]:focus {
  border-color: #00c9ff;
  outline: none;
  
}

/* Button */
.card2 button {
  width: 100%;
  padding: 12px;
  background-color: #00c9ff;
  color: white;
  font-size: 16px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-transform: uppercase;
}

.card2 button:hover {
  background-color: #009dd6;
}

/* Error and Success Messages */
.card2 p[style*="color: red"] {
  color: #ff4d4d;
  font-size: 14px;
  margin-bottom: 20px;
}

.card2 p[style*="color: green"] {
  color: #28a745;
  font-size: 14px;
  margin-bottom: 20px;
}

/* Responsive Design */

/* Tablets */
@media (max-width: 1024px) {
  .card2 {
    padding: 30px;
    max-width: 350px;
  }

  .card2 h1 {
    font-size: 22px;
  }

  .card2 p {
    font-size: 15px;
  }

  .card2 button {
    font-size: 15px;
    padding: 10px;
  }

  .card2 input[type="text"] {
    padding: 10px;
    font-size: 15px;
  }
}

/* Mobile Phones */
@media (max-width: 768px) {
  .card2 {
    padding: 20px;
    max-width: 300px;
  }

  .card2 h1 {
    font-size: 20px;
  }

  .card2 p {
    font-size: 14px;
  }

  .card2 button {
    font-size: 14px;
    padding: 10px;
  }

  .card2 input[type="text"] {
    padding: 10px;
    font-size: 14px;
  }
}

/* Smaller Mobile Phones */
@media (max-width: 480px) {
  .card2 {
    padding: 15px;
    max-width: 100%; /* Make the card take up full width on very small screens */
  }

  .card2 h1 {
    font-size: 18px;
  }

  .card2 p {
    font-size: 13px;
  }

  .card2 button {
    font-size: 13px;
    padding: 8px;
  }

  .card2 input[type="text"] {
    padding: 8px;
    font-size: 13px;
  }
}

.card2 .back-button {
  font-size: 16px;
  color: #000000; /* Blue color for the back button */
 
  background-color: white;
  display: flex;
  justify-content: flex-start;
}

.card2 .back-button:hover {
  background-color: white;
}
