/* Footer main styling */
.footer {
  background-color: #F5F5F7; /* Apple-like off-white/gray */
  color:  #1D1D1F; /* White text */
  padding: 40px 20px;
  text-align: left;
  font-family: 'Open Sans', sans-serif; /* Consistent font */
}

/* Footer content layout using flexbox */
.footer-content {
  display: flex;
  justify-content: space-between; /* Spread the items */
  align-items: flex-start; /* Align items to the top */
  max-width: 1200px;
  margin: 0 auto;
  flex-wrap: nowrap; /* Prevent wrapping */
  gap: 20px;
}

.footer h4 {
  font-size: 1.2rem;
  margin-bottom: 15px;
  color:  #1D1D1F;
  font-weight: 600;
}

.footer p, .footer a {
  font-size: 1rem;
  margin-bottom: 10px;
  color:  #1D1D1F;
}

.footer a {
  text-decoration: none;
  color: #0078d4; /* Consistent link color */
}

.footer a:hover {
  text-decoration: underline;
}

/* Footer link list */
.footer-links ul {
  list-style: none;
  padding: 0;
  display: flex; /* Use Flexbox to make items align horizontally */
  gap: 15px; /* Add spacing between links */
}

.footer-links ul li {
  margin-bottom: 0; /* Remove bottom margin since they are now in a row */
}


.footer-links ul li a {
  font-size: 1rem;
  font-weight: 400;
  color:  #1D1D1F;
}

.footer-links ul li a:hover {
  color:  #1D1D1F; /* White on hover */
}

/* Footer social icons */
.footer-social ul {
  list-style: none;
  padding: 0;
  display: flex;
  gap: 15px;
}

.footer-social ul li a {
  font-size: 1.2rem;
  color:  #1D1D1F;
}

.footer-social ul li a:hover {
  color: #0078d4;
}

/* Footer bottom section */
.footer-bottom {
  text-align: center;
  padding-top: 20px;
  border-top: 1px solid #444;
  margin-top: 20px;
  font-size: 0.9rem;
}

.footer-bottom p {
  color:  #1D1D1F;
  font-size: 0.9rem;
}

/* Ensure no stacking for responsive */
@media (max-width: 768px) {
  .footer-content {
      flex-wrap: nowrap; /* Ensure no wrapping on smaller screens */
      flex-direction: row; /* Keep row direction */
      justify-content: space-between;
      gap: 15px; /* Adjust gap for mobile */
  }
}

@media (max-width: 480px) {
  .footer-content {
      flex-wrap: nowrap; /* Prevent stacking even on very small screens */
      justify-content: space-between; /* Keep items spread across the row */
      gap: 10px; /* Adjust gap for smaller screens */
  }

  .footer h4 {
      font-size: 1.1rem; /* Slightly reduce heading size */
  }

  .footer p, .footer a {
      font-size: 0.9rem; /* Slightly reduce text size */
  }

  .footer-bottom p {
      font-size: 0.85rem; /* Adjust text size for very small devices */
  }
}


@media (max-width: 768px) {
  .footer-links ul {
    flex-direction: column; /* Stack the links vertically */
    gap: 10px; /* Adjust the gap for vertical spacing */
  }

  .footer-links ul li {
    margin-bottom: 10px; /* Add some bottom margin for better spacing */
  }
}

@media (max-width: 480px) {
  .footer-links ul {
    flex-direction: column; /* Ensure links stay stacked vertically */
    gap: 8px; /* Adjust the gap further for smaller screens */
  }

  .footer-links ul li {
    margin-bottom: 8px; /* Adjust bottom margin for small screens */
  }
}