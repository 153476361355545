.video-section-apple-like {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F5F5F7; /* Apple-like off-white/gray */
  padding: 40px 0;
}

.video-card {
  position: relative;
  background-color: #ffffff;
  border-radius: 24px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  width: 80%;
  max-width: 1000px;
  margin: 0 auto;
}

.apple-like-video {
  display: block;
  width: 100%;
  height: auto;
  object-fit: contain;
  border-radius: inherit;
  background-color: #ffffff;
}

/* Container for the custom buttons */
.video-controls {
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: flex; 
  gap: 8px; /* space between buttons */
}

.control-button {
  border: none;
  background-color: rgba(0,0,0,0.4);
  color: #fff;
  font-size: 16px;
  padding: 8px 10px;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  transition: background-color 0.2s ease;
}

.control-button:hover {
  background-color: rgba(0,0,0,0.6);
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .video-card {
    width: 90%;
  }
  .control-button {
    font-size: 14px;
    padding: 6px 8px;
  }
}
