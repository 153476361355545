/* ---------------------------------
   Global / Base Styles
------------------------------------ */
/* Use a system/Apple-inspired font stack for a more Apple-like feel */
body,
.account-container {
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin: 0;
  padding: 0;
  color: #1D1D1F; /* Apple's typical near-black text color */
}

/* ---------------------------------
   Overall Container
------------------------------------ */
.account-container {
  display: flex;
  flex-direction: row;
  background-color: #F5F5F7; /* Light Apple-like gray */
  min-height: 100vh;
  padding: 40px;
  box-sizing: border-box;
}

/* ---------------------------------
   Sidebar
------------------------------------ */
.sidebar-1 {
  width: 250px;
  background-color: #FFFFFF; /* Clean white background */
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 1px 3px rgba(60, 64, 67, 0.1);
  /* Subtle shadow for a premium feel */
  transition: transform 0.3s ease;
}

.sidebar-1 ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar-1 ul li {
  padding: 12px 16px;
  font-size: 1rem;
  color: #515151;
  cursor: pointer;
  margin-bottom: 10px;
  border-radius: 8px;
  transition: background-color 0.2s ease, transform 0.2s ease;
}

.sidebar-1 ul li:hover {
  background-color: #F2F2F2;
  transform: translateY(-2px);
}

.sidebar-1 ul li.active {
  background-color: #EAEAEA;
  color: #000000;
  font-weight: 500;
}

/* ---------------------------------
   Account Details Section
------------------------------------ */
.account-details {
  flex-grow: 1;
  margin-left: 40px;
  padding: 30px;
  background-color: #FFFFFF;
  border-radius: 12px;
  box-shadow: 0 1px 3px rgba(60, 64, 67, 0.1);
  transition: all 0.3s ease;
}

/* Section styling */
.section h2 {
  font-size: 2rem;
  font-weight: 600;
  color: #1D1D1F;
  margin-bottom: 24px;
  border-bottom: 1px solid #E5E5E5;
  padding-bottom: 10px;
}

/* ---------------------------------
   Profile Fields
------------------------------------ */
.profile-field {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.profile-field label {
  margin-bottom: 8px;
  font-weight: 500;
  color: #3A3A3C;
  font-size: 0.95rem;
}

.profile-field input {
  width: 100%;
  padding: 10px 14px;
  border: 1px solid #C7C7CC;
  border-radius: 8px;
  background-color: #F9F9F9;
  color: #333;
  font-size: 1rem;
  outline: none;
  transition: border-color 0.2s ease, background-color 0.2s ease;
  box-sizing: border-box;
}

.profile-field input:hover,
.profile-field input:focus {
  border-color: #A3A3A3;
  background-color: #FFF;
}

.profile-field input.registration-key {
  color: #E74C3C;
}

input[disabled] {
  background-color: #F0F0F0;
  color: #8E8E93;
}

/* ---------------------------------
   Buttons
------------------------------------ */
.button-container {
  display: flex;
  justify-content: flex-start;
  margin-top: 30px;
  gap: 12px;
}

.button {
  padding: 12px 24px;
  background-color: #0071E3;  /* Apple-like blue */
  color: #FFFFFF;
  border: none;
  border-radius: 24px;
  cursor: pointer;
  font-size: 0.95rem;
  font-weight: 500;
  transition: background-color 0.2s ease, transform 0.15s ease;
  box-shadow: 0 2px 4px rgba(60, 64, 67, 0.1);
}

.button:hover {
  background-color: #005BB5;
  transform: translateY(-2px);
}

.button:disabled {
  background-color: #A6A6A6;
  cursor: not-allowed;
}

.button.reset {
  background-color: #D95C5C; 
}

.button.reset:hover {
  background-color: #BD4D4D;
}

/* ---------------------------------
   Modal Styling
------------------------------------ */
.modal-account {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(28, 28, 30, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.modal-content-account {
  background-color: #FFFFFF;
  padding: 30px;
  border-radius: 12px;
  width: 420px;
  max-width: 90%;
  text-align: center;
  box-shadow: 0 2px 8px rgba(0,0,0,0.2);
  color: #1D1D1F;
}

.modal-content-account h2 {
  margin-bottom: 10px;
  font-size: 1.6rem;
  font-weight: 600;
  color: #1D1D1F;
}

.modal-content-account p {
  margin-bottom: 20px;
  font-size: 0.95rem;
  color: #666666;
}

/* ---------------------------------
   Error / Success Messages
------------------------------------ */
.message-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 20px 0;
}

.error-message-1, .success-message-1 {
  text-align: center;
  width: 100%;
  max-width: 350px;
  padding: 12px;
  border-radius: 8px;
  font-size: 0.95rem;
  margin: 0 auto 16px auto;
}

.error-message-1 {
  color: #B80D0D; 
  background: #FCE9E9;
}

.success-message-1 {
  color: #1D831D;
  background: #E8FCE8;
}

/* Modal input fields */
.input-group {
  margin-bottom: 20px;
  text-align: left;
}

.input-group label {
  display: block;
  font-size: 0.95rem;
  font-weight: 500;
  margin-bottom: 5px;
  color: #3A3A3C;
}

.input-group input {
  width: 100%;
  padding: 10px 14px;
  border: 1px solid #C7C7CC;
  border-radius: 8px;
  background-color: #F9F9F9;
  color: #333333;
  font-size: 1rem;
  box-sizing: border-box;
  transition: border-color 0.2s ease, background-color 0.2s ease;
}

.input-group input:hover,
.input-group input:focus {
  border-color: #A3A3A3;
  background-color: #FFF;
}

.input-reset-code,
.input-new-password,
.input-confirm-password {
  margin-bottom: 20px;
}

/* Submit button in modal */
.submit-button {
  width: 100%;
  padding: 14px;
  background-color: #0071E3;
  color: #FFFFFF;
  border: none;
  border-radius: 24px;
  font-size: 0.95rem;
  cursor: pointer;
  text-transform: uppercase;
  transition: background-color 0.2s ease, transform 0.15s ease;
  font-weight: 500;
}

.submit-button:hover {
  background-color: #005BB5;
  transform: translateY(-2px);
}

/* ---------------------------------
   Platform Buttons (Download Section)
------------------------------------ */
.platform-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
}

.download-now-text {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 16px;
  color: #1D1D1F;
}

.platform-button-wrapper {
  display: flex;
  justify-content: center;
  gap: 40px;
  margin-top: 8px;
}

/* Container for each platform button + label */
.platform-button-container {
  text-align: center;
  position: relative; /* important for dropdown positioning */
}

/* The button itself */
.platform-button {
  width: 70px;
  height: 70px;
  border: none;
  border-radius: 14px;
  background-color: #F2F2F2;
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
  cursor: pointer;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.platform-button:hover {
  background-color: #E5E5E7;
  transform: translateY(-2px);
  box-shadow: 0 2px 6px rgba(0,0,0,0.1);
}

.platform-text {
  color: #3A3A3C;
  margin-top: 8px;
  font-size: 0.9rem;
}

/* ---------------------------------
   Mac Dropdown Styles
------------------------------------ */
/* This container will hold the Mac button and the dropdown menu */
.mac-dropdown-container {
  position: relative;
}

/* The dropdown menu for ARM64/Intel options */
.mac-dropdown-menu {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 80px; /* adjust for spacing below the Mac button */
  left: 50%;
  transform: translateX(-50%);
  background-color: #FFFFFF;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.2);
  overflow: hidden;
  min-width: 140px;
  z-index: 10;
}

.mac-dropdown-item {
  background: transparent;
  border: none;
  padding: 0.75rem 1rem;
  text-align: left;
  font-size: 0.95rem;
  cursor: pointer;
  transition: background 0.2s ease;
}

.mac-dropdown-item:hover {
  background: rgba(0, 0, 0, 0.1);
}

/* ---------------------------------
   Responsive / Media Queries
------------------------------------ */
/* Tablets (max-width: 768px) */
@media (max-width: 768px) {
  .account-container {
    flex-direction: column;
    padding: 20px;
  }

  .sidebar-1 {
    width: 100%;
    margin-bottom: 20px;
    padding: 15px;
    text-align: center;
  }

  .sidebar-1 ul {
    display: flex;
    justify-content: space-around;
  }

  .sidebar-1 ul li {
    font-size: 0.95rem;
    margin-bottom: 0;
  }

  .account-details {
    margin-left: 0;
    margin-top: 20px;
    padding: 20px;
  }

  .section h2 {
    font-size: 1.8rem;
  }

  .platform-button-wrapper {
    gap: 20px;
  }
}

/* Mobile (max-width: 480px) */
@media (max-width: 480px) {
  .section h2 {
    font-size: 1.4rem;
  }

  .profile-field label {
    font-size: 0.85rem;
  }

  .profile-field input {
    font-size: 0.9rem;
  }

  .button {
    font-size: 0.85rem;
  }

  .submit-button {
    font-size: 0.85rem;
  }

  .platform-button {
    width: 60px;
    height: 60px;
  }

  .download-now-text {
    font-size: 1rem;
  }

  .platform-button-wrapper {
    flex-direction: column;
    gap: 24px;
  }

  .mac-dropdown-menu {
    top: 70px; /* Adjust for slightly smaller button size */
  }
}
