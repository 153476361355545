/* Header Container */
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  /* Background and Text Color */
  background-color: #F5F5F7; /* Apple-like off-white/gray */
  color: #000000;

  /* Sticky Header */
  position: sticky;
  top: 0;
  width: 100%;
  height: 60px; /* Adjusted for better spacing */
  padding: 0 20px;
  box-sizing: border-box;
  z-index: 1000;
}

/* Hamburger Icon (Mobile Only) */
.hamburger-icon {
  display: none; /* Hidden on desktop by default */
  cursor: pointer;
  z-index: 2001; /* Above overlay */
}

.hamburger-icon:hover {
  color: #636365;
}

/* Show Hamburger Icon on Smaller Screens */
@media (max-width: 768px) {
  .hamburger-icon {
    display: flex;
    align-items: center;
    margin-right: 10px;
  }
}

/* Logo */
.logo1 img {
  height: 30px; 
  width: auto;
  display: block;
}

/* Navigation Container */
.nav {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.nav ul {
  display: flex;
  list-style: none;
  gap: 30px;
  margin: 0;
  padding: 0;
}

/* Individual Navigation Links */
.nav ul li a,
.dropdown-toggle {
  color: #6E6E73;
  text-decoration: none;
  font-size: 14px;
  font-weight: 400;
  padding: 2px 0;
  transition: color 0.2s ease;
}



.nav ul li a:hover,
.dropdown-toggle:hover {
  color: #a1a1a6;
}

/* Specific Styling for Account Link (Optional) */
.nav ul li a[href="/account"] {
  color: #4c4c4c;
}

.nav ul li a[href="/account"]:hover {
  color: #a1a1a6;
}

/* Active Link Styling */
.nav ul li.active a,
.nav ul li.active button {
  color: #0073e6; /* Active color */
  font-weight: bold;
}

/* Products Menu Positioning */
.products-menu {
  position: relative;
}

.dropdown-toggle {
  background: none;
  border: none;
  cursor: pointer;
}

/* Submenu Styling */
.submenu {
  display: none; /* Hidden by default */
  position: absolute;
  top: 24px;
  left: 0;
  background-color: #f6f6f6; /* Changed from black to dark grey */
  padding: 12px 0; /* Increased padding */
  margin: 0;

  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  z-index: 1000;
  min-width: 85px; /* Ensures minimum width */
  text-align: center;
  color: #ffffff; /* Adjusted text color for readability */
}

.products-menu:hover .submenu {
  display: block; /* Show submenu on hover */
}

.submenu li a {
  display: block;
  padding: 10px 20px; /* Increased padding */
  font-size: 15px;    /* Slightly larger font */
  text-align: center;
  color: #ffffff; /* Text color matching the submenu background */
  text-decoration: none;
}

.submenu li a:hover {
  background-color: #444; /* Slightly lighter shade for hover */
}

/* Desktop-only Auth Links */
.desktop-auth-link {
  display: block;
}

/* Mobile Menu Styles */
@media (max-width: 768px) {
  .nav {
    position: fixed;
    top: 0;
    right: -100%;
    width: 80%;
    height: 100%;
    flex-direction: column;
    background-color: #e6e6e6;
    justify-content: center;
    align-items: center;
    transition: right 0.3s ease;
    z-index: 2000;
  }

  .nav-open {
    right: 0; /* Slide in the menu */
  }

  .nav ul {
    flex-direction: column;
    gap: 20px;
  }

  /* Hide Desktop Auth Links on Mobile */
  .desktop-auth-link {
    display: none;
  }

  /* Mobile Auth Links */
  .mobile-auth-link a,
  .mobile-auth-link button {
    width: 100%;
    justify-content: center;
    /* Ensure buttons span full width */
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 10px 0;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: background 0.3s, color 0.3s;
    text-decoration: none;
    color: #ffffff;
    border: none;
  }

  /* Style Mobile Auth Buttons */
  .mobile-auth-link .login-button {
    background-color: #007bff; /* Primary color */
  }

  .mobile-auth-link .login-button:hover {
    background-color: #0056b3;
  }

  .mobile-auth-link .logout-button {
    background-color: #dc3545; /* Danger color */
    font-size: 13px;
  }

  .mobile-auth-link .logout-button:hover {
    background-color: #a71d2a;
  }

  /* Style for Account, Sign In, Sign Up Links */
 

 
}

/* "Actions" Area on the Right */
.actions {
  display: flex;
  align-items: center;
  gap: 20px;
}

/* "Try for free" Button */
.try-button {
  background-color: #34c759;
  color: #ffffff;
  border: 1px solid;
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 6px;
  transition: background 0.3s, color 0.3s;
  text-decoration: none;
}

.try-button:hover {
  background: #f5f5f7; 
  color: #000;
}

/* Desktop Auth Buttons */
.desktop-auth-buttons .login-button,
.desktop-auth-buttons .logout-button {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 6px 12px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background 0.3s, color 0.3s;
}

.desktop-auth-buttons .login-button {
  background-color: #007bff; /* Primary color */
  color: #ffffff;
}

.desktop-auth-buttons .login-button:hover {
  background-color: #0056b3;
}

.desktop-auth-buttons .logout-button {
  background-color: #dc3545; /* Danger color */
  color: #ffffff;
}

.desktop-auth-buttons .logout-button:hover {
  background-color: #a71d2a;
}

/* Icons Inside Buttons */
.desktop-auth-buttons .icon,
.logout-button .icon,
.login-button .icon {
  display: inline-block;
  vertical-align: middle;
}

/* Hide Desktop Auth Buttons on Mobile */
@media (max-width: 768px) {
  .desktop-auth-buttons {
    display: none;
  }

  /* Ensure "Try for free" remains visible */
  .actions .try-button {
    display: flex;
  }
}

/* Mobile-only Auth Links */
.mobile-auth-link {
  /* Optional: Additional styles for the <li> if needed */
}

/* Overlay for Mobile Menu Background Fade */
.menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  z-index: 1000;
}

.nav-open ~ .menu-overlay {
  opacity: 1;
  visibility: visible;
}

/* Hide Menu Overlay and Hamburger Icon on Desktop */
@media (min-width: 769px) {
  .menu-overlay {
    display: none;
  }

  .hamburger-icon {
    display: none;
  }
}
