/* Container (inherits body styles) */
.container-signup {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  background-color: #f9f9f9;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* Full viewport height */
  width: 100%;
  padding: 20px; /* Add padding for small screens */
  box-sizing: border-box;
}

/* Card */
.card3 {
  display: flex;
  background: white;
  border-radius: 20px;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  max-width: 1200px;
  width: 100%;
  flex-direction: row; /* Ensure row direction on large screens */
}

/* Form Section */
.form-section3 {
  padding: 40px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box; /* Ensure padding is considered in width */
}

/* Title */
.title {
  font-size: 2.5em;
  text-align: center;
  color: #333;
  margin-bottom: 10px; /* Reduce bottom margin for better spacing */
}

.brand-name {
  color: #00C9FF;
}

/* Subtitle */
.subtitle {
  font-size: 1em;
  color: #666;
  text-align: center;
  margin-bottom: 30px;
}

/* Input */
.input-wrapper {
  margin-bottom: 15px;
  width: 100%;
}

.signup[type="text"],
.signup[type="email"],
.signup[type="password"],
select {
  width: 100%;
  padding: 15px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 10px;
  font-size: 1em;
  background-color: #f9f9f9;
  box-sizing: border-box; /* Ensure inputs fill the container */
}

input:focus,
select:focus {
  outline: none;
  border-color: #00C9FF;
  box-shadow: 0 0 5px #00C9FF;
}

/* Button */
.button {
  background: #00C9FF;
  color: white;
  padding: 15px;
  font-size: 1em;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-transform: uppercase;
  width: 100%; /* Ensure button is full width */
}

.button:hover {
  background: #009dd6;
  box-shadow: 0 10px 20px rgba(0, 191, 255, 0.8);
}

/* Checkbox */
.checkbox-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.checkbox {
  margin-right: 10px;
  transform: scale(1.2);
}

.checkbox-label {
  font-size: 0.9em;
}

/* Link */
.link-wrapper {
  text-align: center;
}

.small-text a {
  color: #00C9FF;
  text-decoration: none;
}

.small-text a:hover {
  text-decoration: underline;
}

/* Image Section */
.image-section {
  flex: 1;
  background: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 85%);
}

.image {
  max-width: 80%;
  height: auto;
  border-radius: 15px;
}

/* Responsive - Tablets and smaller */
@media (max-width: 1024px) {
  .card3 {
    height: auto; /* Allow height to adapt for tablets */
  }

  .form-section3 {
    padding: 30px; /* Reduce padding for smaller screens */
  }

  .title {
    font-size: 2em; /* Adjust font size */
  }

  .signup {
    padding: 12px; /* Reduce input padding for tablets */
  }

  .button {
    padding: 12px; /* Reduce button padding */
  }
}

/* Responsive - Mobile Phones */
@media (max-width: 768px) {
  .card3 {
    flex-direction: column; /* Stack form and image vertically */
    height: auto; /* Adjust height */
  }

  .form-section3 {
    padding: 20px; /* Reduce padding */
  }

  .title {
    font-size: 1.8em; /* Adjust font size */
  }

  /* Hide image section on mobile */
  .image-section {
    display: none; /* Do not show image on mobile */
  }

  .signup,
  .button {
    width: 100%; /* Ensure inputs and button fit full width on mobile */
  }

  .signup {
    padding: 10px; /* Reduce input padding for mobile */
  }

  .button {
    padding: 12px; /* Adjust button padding */
  }
}

/* Responsive - Smaller Mobile Phones */
@media (max-width: 480px) {
  .form-section3 {
    padding: 15px; /* Further reduce padding */
  }

  .title {
    font-size: 1.5em; /* Reduce title font size */
  }

  .signup {
    padding: 8px; /* Further reduce input padding */
  }

  .button {
    padding: 10px; /* Reduce button padding */
  }

  .image-section {
    display: none; /* Ensure image is hidden */
  }
}

.back-button-signup{
  font-size: 16px;
  color: #000000; /* Blue color for the back button */
 
  background-color: white;
  display: flex;
  justify-content: flex-start;
  border: none;
}

.password-strength-meter {
  width: 100%;
  height: 10px;
  background-color: #e0e0e0;
  border-radius: 5px;
  margin-bottom: 5px;
  position: relative;
}

.password-strength-bar {
  height: 100%;
  border-radius: 5px;
  transition: width 0.3s ease, background-color 0.3s ease;
}

.password-strength-label {
  font-size: 0.8em;
  color: #555;
  margin-top: 2px;
  display: block;
  text-align: right;
}

/* Password Feedback */
.password-feedback {
  font-size: 0.8em;
  color: #ff4d4d;
  margin-top: -10px;
  margin-bottom: 10px;
}

/* Password Requirements */
.password-requirements {
  font-size: 0.9em;
  color: #555;
  margin-top: 10px;
}

.password-requirements ul {
  list-style: none;
  padding-left: 0;
}

.password-requirements li {
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}

.password-requirements .valid::before {
  content: '✔';
  color: #00e600;
  margin-right: 8px;
}

.password-requirements .invalid::before {
  content: '✖';
  color: #ff4d4d;
  margin-right: 8px;
}

/* Error Message */
.error-message {
  color: #ff4d4d;
  font-size: 0.9em;
  margin-bottom: 15px;
  text-align: center;
}