/* Success page styling */
.success-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 80vh;
    text-align: center;
    font-family: 'Arial', sans-serif;
  }
  
  .loading {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .spinner {
    border: 6px solid #f3f3f3;
    border-top: 6px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
    margin-bottom: 20px;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .success-message {
    background-color: #e6ffe6;
    border: 2px solid #28a745;
    padding: 20px;
    border-radius: 10px;
    max-width: 400px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .success-message .checkmark {
    font-size: 50px;
    color: #28a745;
  }
  
  .success-message h2 {
    color: #28a745;
  }
  
  .success-message p {
    color: #333;
  }
  
  .error-container {
    background-color: #ffe6e6;
    border: 2px solid #dc3545;
    padding: 20px;
    border-radius: 10px;
    max-width: 400px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .error-container h2 {
    color: #dc3545;
  }
  
  .error-container p {
    color: #333;
  }
  
  .error-container button {
    background-color: #dc3545;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
    font-size: 16px;
  }
  
  .error-container button:hover {
    background-color: #c82333;
  }
  